import React, { useEffect } from 'react'
import { Form, Transition } from 'semantic-ui-react'
import { SpellcheckStatus } from '.'
import { OneNoteOutLine } from './api'

interface Props {
  documentText: string
  selectionStart: number
  selectionLength: number
  status: SpellcheckStatus
  original: string
  onError: (error: Error) => void
  outlines: OneNoteOutLine[]
  activeOutline?: OneNoteOutLine
  setCheckFrom: (newValue: number) => void
}

interface OutlineViewProps {
  outline?: OneNoteOutLine
  selectionStart: number
  selectionEnd: number
  setCheckFrom: (newValue: number) => void
}

const setSelectionInTextArea = (selectionStart: number, selectionEnd: number, outline?: OneNoteOutLine) => {
  if ( !outline ) return;
  const startOffset = selectionStart - outline.startOffset
  const endOffset = selectionEnd - outline.startOffset

  let select = true
  if ( startOffset === endOffset || startOffset < 0 || endOffset < 0 ) {
    select = false
  }
  if ( !select ) return;

  const textarea = document.getElementById('outline-view-textarea') as HTMLTextAreaElement | null
  if ( !textarea ) return;

  textarea.focus()
  const fullText = textarea.value
  textarea.value = fullText.substring(0, endOffset)
  textarea.scrollTop = textarea.scrollHeight
  textarea.value = fullText
  textarea.setSelectionRange(startOffset, endOffset)
}

const getCaretPosInTextarea = () => {
  const textarea = document.getElementById('outline-view-textarea') as HTMLTextAreaElement | null
  if ( !textarea ) return 0

  // console.log("textarea selection", textarea.selectionStart, textarea.selectionEnd, textarea.selectionDirection)
  return textarea.selectionStart
}

const OutlineView = (props: OutlineViewProps) => {
  const { outline, selectionStart, selectionEnd } = props

  useEffect(() => {
    setSelectionInTextArea(selectionStart, selectionEnd, outline)
  })

  if ( !outline ) return <></>
  
  const value = outline.paragraphs.map(p => p.text).join('')
  const rows = Math.max(Math.round(value.length / 20), 1)

  return <Transition transitionOnMount animation='fade right'>
    <div className='outline'>
      <div className='handle'><span>◂▸</span></div>
      <Form className='content'>
        <Form.TextArea
          id='outline-view-textarea'
          rows={ rows }
          spellCheck={ false }
          value={ value }
          onMouseUp={() => {
            const caretPos = getCaretPosInTextarea()
            const newCheckFrom = caretPos + outline.startOffset

            props.setCheckFrom(newCheckFrom)
          }}
        />
      </Form>
    </div>
  </Transition>
}

/**
 * Put contentEditable on hold for now...
 * @param selectionStart 
 * @param selectionEnd 
 * @param outline 
 
const setSelectionInEditor = (selectionStart: number, selectionEnd: number, outline?: OneNoteOutLine) => {
  if ( !outline ) return;
  const startOffset = selectionStart - outline.startOffset
  const endOffset = selectionEnd - outline.startOffset

  let select = true
  if ( startOffset === endOffset || startOffset < 0 || endOffset < 0 ) {
    select = false
  }
  if ( !select ) return;

  const div = document.getElementById('outline-view-editor')
  if ( !div ) return;

  // const divver = bililiteRange(div)
  // console.log("divver", divver)
  // bililiteRange(div).bounds([startOffset, endOffset]).select()
  // div.focus()


  let startContainer: HTMLElement | Node = div
  let endContainer: HTMLElement | Node = div
  let rangeStartOffset = 0
  let rangeEndOffset = 0
  let loopIndex = 0
  
  const range = document.createRange()
  const textNodes = document.createNodeIterator(div, NodeFilter.SHOW_TEXT)
  
  // Whitespace is considered a character by engine but won't be included in 
  // nodeiterator. 
  let lastBlockParent

  nodeLoop: while ( textNodes.nextNode() ) {
    const { referenceNode } = textNodes
    const { textContent } = referenceNode

    let closestBlockParent = referenceNode.parentElement as HTMLElement
    while ( window.getComputedStyle(closestBlockParent).display !== 'block' ) {
      closestBlockParent = closestBlockParent.parentElement as HTMLElement
    }

    if ( !!lastBlockParent && closestBlockParent !== lastBlockParent ) {
      loopIndex++
    }
    lastBlockParent = closestBlockParent
    
    if ( textContent === null ) {
      continue
    }
    
    for ( let i = 0; i < textContent.length; i++ ) {

      if ( loopIndex === startOffset ) {
        startContainer = referenceNode
        rangeStartOffset = i
      }
      
      if ( loopIndex === endOffset ) {
        endContainer = referenceNode
        rangeEndOffset = i
        break nodeLoop
      }
      
      loopIndex++
    }
  }

  if ( rangeEndOffset === 0 && endContainer === div ) {
    const { referenceNode } = textNodes
    endContainer = referenceNode

    const text = referenceNode.textContent || ''
    rangeEndOffset = text.length
  }

  try {
    range.setStart(startContainer, rangeStartOffset)
    range.setEnd(endContainer, rangeEndOffset)

    const selection = document.getSelection()
    if ( selection ) {
      selection.removeAllRanges()
      selection.addRange(range)
    }
    div.focus()
  } catch(error) {
    console.log(error.message)
  } 
}

const EditableOutlineView = (props: OutlineViewProps) => {
  const { outline, selectionStart, selectionEnd } = props

  useEffect(() => {
    setSelectionInEditor(selectionStart, selectionEnd, outline)
  })

  if ( !outline ) return <></>

  const startOffset = selectionStart - outline.startOffset
  const endOffset = selectionEnd - outline.startOffset

  let select = true
  if ( startOffset === endOffset || startOffset < 0 || endOffset < 0 ) {
    select = false
  }

  const innerHTML = outline.paragraphs.reduce((html, paragraph) => {
    return html + paragraph.html
  }, '')
  
  return <Transition transitionOnMount animation='fade right'>
    <div className='outline'>
      <div className='handle'><span>◂▸</span></div>
      <div
        id='outline-view-editor'
        className='content'
        style={{ whiteSpace: 'pre' }}
        contentEditable
        aria-readonly
        spellCheck={ false }
        dangerouslySetInnerHTML={{ __html: innerHTML }}
      ></div>
    </div>
  </Transition>
}
*/

const ExcerptBar = (props: Props) => {
  const { selectionStart, selectionLength, activeOutline } = props

  const selectionEnd = selectionStart + selectionLength
  return <div className='outline-view' style={{ padding: '0 1em 1em' }}>
    <OutlineView
      outline={ activeOutline }
      selectionEnd={ selectionEnd }
      selectionStart={ selectionStart }
      setCheckFrom={ props.setCheckFrom }
    />
  </div>
}

export default ExcerptBar